.drop-down-container{
    position: relative;
    z-index: 1;
}
.dropdown-menu-item-pc{
    background: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    position: absolute;
    padding: 1.38rem 2.44rem 0.38rem 1.25rem;
    white-space: nowrap;
}
.dropdown-menu-item-pc ul li{
    list-style: none;
}
.dropdown-menu-item-pc ul{
    padding: 0;
    margin: 0;
}

