.align-center{
    max-width: 1200px;
    margin: 0 auto;
}
.home-pc{ 
    padding-bottom: 46px;
 } 
.product-main-title-pc{
    margin-bottom:  4.12rem;
}
.our-product-container-wrapper,
.our-product-heading-wrapper{
    padding: 0 20px;
}
.product-main-title-pc .product-into--title-pc{
    color: #E8363B;
    font-family: Roboto;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 4.125rem */
    margin: 0;
    padding: 0;
}
.product-main-title-pc .product-into--title2-pc{
    color: #222;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 200%; /* 2rem */
    padding: 0;
    margin: 0;
}
.product-container-pc{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
    column-gap: 2rem;
    align-items: center;
}
.accordion ~ .footer { 
    margin-top: 25px;
    border-top: 1px solid #CAD4DB; 
}