.hover-accordion{
    width: 17.25rem;
    height: 31.25rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid #DEDEDE;
    background: #FFF;
    padding: 1.25rem;
    position: relative;
    flex-shrink: 0;
}
.hover-accordion-logo-container{
    position: absolute;
    top: 5rem;
}
.hover-accordion-logo-container .hover-accordion-logo--img{
    max-height: 3.43rem;
    display: block;
    padding: 0;
    margin: 0;
    text-align: left;
    width: auto
}
.hover-accordion-heading-container .hover-accordion-header--title{
    width: 234px;
    color: #222;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 200%; /* 2rem */
    margin: 0;
    position: absolute;
    top: 8.62rem;
}
.hover-accordion-image-container{
    position: absolute;
    top: 10.5rem;
    left: 0;
    height: 14.3125rem;
    width: 100%;
    background-image: url("/public/laptop-image-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.hover-accordion-image-container .laptop-image-container{
    position: absolute;
    left: 0;
    right: 2.01rem;
    bottom: 0;
    width: 14.27069rem;
    height: 10.3985rem;
}
.hover-accordion-content--button{
    position: absolute;
    top: 27.5rem;
    border-radius: 0.375rem;
    background: #F3F3F3;
    font-family: Roboto;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.21875rem */
}
.hover-accordion--expanded .hover-accordion-logo-container {
    top: 10%;
    transform: translateY(-20%);
    transition: .4s;
}
.hover-accordion--expanded .hover-accordion-header--title {
    top: 5.5rem;
    transition: .4s;
}
.hover-accordion-content{
    position: absolute;
    top: 9.62rem;
    transition: .4s;
}
.hover-accordion-content--description{
    color: #222;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.3125rem */
    max-width: 14.0625rem;;
}
.hover-accordion-content--description ul {
    margin: 0;
    list-style-position: outside;
    padding-left: 1rem;
    list-style-type: square;
}
.hover-accordion-content--description ul li + li{
    margin-top: 0.94rem;
}

.hover-accordion-content--description ul li::marker{
    color: red;
}

.hover-accordion-content--button.button-solid {
    transition: .4s;
}
