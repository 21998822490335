.header{
    display: flex;
    align-items: center;
    height: 4.063rem;
    padding: 0.438rem 1.063rem;
    position: sticky;
    top: 0;
    left: 0;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    z-index: 99;
}


.header--qburst-logo-icon {
    display: block;
    width: 6.188rem;
}

.header--qburst-logo-icon img {
    display: block;
    width: 100%;
}

.menu-drawer {
    width: 100%;
    background-color: #ffff;
    height: calc(100% - 4.063rem);
    position: fixed;
    right: -34.375rem;
    top: 4.063rem;
    transition: right linear .2s;
    padding:25px 28px ;

    overflow-y: auto;
    z-index: 40;
    padding-bottom: 77px;
  }

.menu-drawer.open {
    right: 0px;
    transition: right linear .2s;
  }
.menu-title1{
    display:flex;
    color:#222222;
    font-size: 22px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 35.28px;
    word-wrap: break-word
}

.menu-subtitle1{
  color: #222222;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 13px;
  word-wrap: break-word;
  border-bottom: 0.50px #E0E0E0 solid;
  padding-bottom: 35px;
}
.menu-title2{
  display:flex;
  color:#222222;
  font-size: 22px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 35.28px;
  word-wrap: break-word;
  padding-top: 35px;
}
.menu-subtitle2{
  color: #222222;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 15px;
  word-wrap: break-word;
  border-bottom: 0.50px #E0E0E0 solid;
  padding-bottom: 35px;
}
.menu-title3{
  display:flex;
  color:#222222;
  font-size: 22px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 35.28px;
  word-wrap: break-word;
  padding-top: 35px;
}
.menu-subtitle3{
  color: #222222;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 15px;
  word-wrap: break-word;
  border-bottom: 0.50px #E0E0E0 solid;
  padding-bottom: 35px;
}

.arrowExpandMenu{
    margin-left: 0.518rem;
    transform-origin: center; /* 回転の中心を設定 */
    transition: transform 0.4s ease;
    margin-top: 3.5px;
}
.arrowExpandMenu.expanded {
    transform: rotate(180deg);
  }
.arrowCircle{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.313rem;
  
}  
  
.header--language{
    display:flex;
    font-size: 18px;
    line-height: 2.5rem;
    color: #222222;
    border: none;
    background: none;
    cursor: pointer;
    padding-left: 25px;
    background-image: url("/public/menu/languageIcon.svg");
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 35px;
    
}
    

.hamburgerIcon {
    position: relative;
    cursor: pointer;
    width: 25px;
    padding-bottom: 6.5px;
  
}
.hamburgerIcon span {
  display: block;
  height: 2px;
  width: 25px;
  background: #222222;
  border-radius: 9px;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  transform-origin: left center;
}
.hamburgerIcon span +span {
    margin-top: 6px;
}

.hamburgerIcon.open span:nth-child(1) {
  top: -6px;
  left: 0px;
  position: absolute;

  transform: rotate(45deg);
}

.hamburgerIcon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.hamburgerIcon.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 5px;
  left: 0px;
  position: absolute;
}
