* {
  box-sizing: border-box;
}    
.header-pc > div,
.about-us-pc,
.aboutCompany-pc{
  margin-right: 5rem;
  height: 2.8rem;
  text-decoration: none;
}
.header-pc > div,
.header-pc > a{
  cursor: pointer;
}

.menu-title1-pc{
  text-decoration: none;
}
.aboutCompany-pc{
  text-decoration: none;
  margin-right: 5rem;
}

.header-pc{
    display: flex;
    align-items: center;
    padding-top: 1.37rem;
    width: 100%;
    background-color: #fff;
    z-index: 99;
    margin: 0 auto;
    max-width: 1200px;
}
.header--qburst-logo-icon-pc {
    display: block;
    width: 8.3125rem;
    margin-right: auto;
    height: 3.52794rem;
}
.header--qburst-logo-icon-pc img {
    display: block;
    width: 100%;
}
.menu-title1-pc, 
.menu-title2-pc,
.menu-title3-pc{
    display:flex;
    color: #222;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 147%; /* 1.65375rem */
    word-wrap: break-word;
}
.menu-subtitle1-pc,
.menu-subtitle2-pc,
.menu-subtitle3-pc{
  color: #222;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 147%;
  word-wrap: break-word;
}
.arrowExpandMenu{
    margin-left: .63rem;
    transform-origin: center;
    transition: transform 0.4s ease;
    margin-top: 3.5px;
}
.arrowExpandMenu.expanded {
    transform: rotate(180deg);
  }
.header--language-pc{
  display:flex;
  border: none;
  background: none;
  cursor: pointer;
  padding-left: 25px;
  background-image: url("/public/menu/languageIcon.svg");
  background-repeat: no-repeat;
  background-position: left center;
  color: #222;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 200%; /* 2rem */
  min-width: 5.5rem;
}
.content-pc{
  background-image: url("/public/menu/arrowCircle.svg");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 1.7rem;
  color: #0C093B;
  font-family: Roboto;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 200%; /* 2.125rem */
  margin-bottom: 0.94rem;
}
.content-pc .description-pc{
  color: #0C093B;
  font-family: Roboto;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  margin-left: .8rem;
}
.dropdown-menu-item-pc ul li + li, 
.dropdown-menu-item-pc ul li:first-child {
	border-bottom: 1px solid #E0E0E0;
}

.dropdown-menu-item-pc ul li + li {
  margin-top: 0.87rem;
}