.Evalgator{
    text-align: center;
}

.SectionEvalgator:lang(en) {
    font-family: Roboto;
}
  
.SectionEvalgator:lang(ja) {
    font-family: 'Noto Sans JP', sans-serif;  }

.SectionEvalgator{
    background:#edf7ed;

}
.EvalgatorLogo{
    padding:50px 20px 10px 30px;
}
.EvalgatorDescription{
    color: #0B290D;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 25.74px;
    word-wrap: break-word;
    padding:0px 28px 60px 28px;
    text-align: center;
}
/* Assessment */
.SectionAssessment{
    text-align: center;
}
.AssessmentLogo{
    padding: 0px 0px 0px 5px;
}

.AssessmentTitile{
    color: #4EB04E;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word; 
    margin:0px 0px 11px 0px;
    text-align:center;
}

/* Solution-EvalgatorAssessment */
.AssessmentSolution{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.AssessmentSolution .AssessmentSolution1{
    color: #4EB04E;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.AssessmentSolution .AssessmentSolution2{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.AssessmentSolution .AssessmentSolution3 {
    margin: 0;
    padding: 0 30px;
}
.AssessmentSolution .AssessmentSolution3 li{
    color: #0B290D;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/GreenCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.AssessmentSolution .AssessmentSolution3 li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* button-Assessment */
.AssessmentButton{
    display: flex;
    justify-content: center; 
    gap: 25px;
}
.AssessmentWebButton{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background:#4EB04E; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.AssessmentBrochureButton{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        background:#4EB04E; 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* Interview */
.SectionInterview{
    text-align: center;
}
.Interview{
    padding:150px 0px 0px 0px
}

.InterviewLogo{
    padding: 0px 0px 0px 0px;
    
}
.InterviewTitile{
    color: #4EB04E;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
    padding-bottom: 11px;
    
}
/* Solution-EvalgatorInterview */
.InterviewSolution{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.InterviewSolution .InterviewSolution1{
    color: #4EB04E;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.InterviewSolution .InterviewSolution2{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.InterviewSolution .InterviewSolution3 {
    margin: 0;
    padding: 0 30px;
}
.InterviewSolution .InterviewSolution3 li{
    color: #0B290D;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/GreenCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.InterviewSolution .InterviewSolution3 li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
/* button-Interview */
.InterviewButton{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-bottom: 30px;
}
.InterviewWebButton{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background:#4EB04E; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.InterviewBrochureButton{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background:#4EB04E; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}




