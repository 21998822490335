.main-content{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 65px);
    font-family: 'Noto Sans JP', sans-serif;

    
}
.headingArea .headingArea-heading{
    position: relative;
    color: #222222;
    font-size: 36px;
    font-family: Roboto;
    font-weight: 900;
    line-height: 72px;
    word-wrap: break-word;
    line-height: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 2px;
    z-index: 30;
    top: 49px;
    padding-left: 17px;
}
.headingArea .headingArea-subHeading{
    position: relative;
    color: #E8363B;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 2px;
    z-index: 20;
    padding-left: 17px;
    bottom: 48px;
}

.headingArea .headingArea-background{
    position: absolute;
    color: #FFF5F6;
    font-size: 75px;
    font-family: Roboto;
    font-weight: 900;
    line-height: 150px;
    text-wrap: nowrap;
    z-index: 0;
    top:60px;
    left: 22px;
}



.MainArea{
    padding:0px 25px;
}
.MainArea .motto{
    color: #222222;
    font-size: 21px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: left;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 1px;
}


.MainArea .main-content--description{
    font-size: 14px;
    color: #222222;
    line-height: 1.75rem;
    margin:0 0 60px;
    font-family: 'Noto Sans JP', sans-serif;
}

