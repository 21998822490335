* {
    box-sizing: border-box;
}    
.product-header{
   width: 100%;
   display: flex;
   height: 145px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
}
.go-back-button{
   position: absolute;
   top: 9.5px;
   left: 20px;
   padding: 10px;
   border: none;
   background: none;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
}
.product-logo{
   width: 229px;
   height: 81px;
   margin: 32px 80.5px;
}
.page-title-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}
.page-title{
   width: 216px;
   color: #604CA5;
   font-family: Roboto;
   font-size: 	1.0625rem;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   margin: 0;
}
.page-description-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 24px 31px;
}
.product-description{
   color: #000;
   font-family: Inter;
   font-size: 	0.9375rem;
   font-style: normal;
   font-weight: 400;
   line-height: 129.523%;
}
.product-description ul{
   margin: 0;
   padding-left: 25px;
   list-style-type: disc;
}
.product-description ul ul li:last-child{
   margin-bottom: 15px;
}
.product-demo{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.prodouct-demo--laptop-picture{
   margin: 33px 53px 90px; 
   width: 283.944px;
   height: 166.48px;
}
.prodouct-demo--laptop-picture img{
   height: 100%;
   width: 100%;
   object-fit: fill;
}
.product-demo button{
   margin: 0 91px 35px;
}