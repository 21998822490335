/* ロゴセクション */
.productPage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
p {
    margin: 0;
}
.logoEvalgator{
    width: 100%;
    padding-top: 50px;
}
.logoCrosschannel{
    padding-top: 50px;
}
.backgroundCrosschannel{
    background-color: #F3EEFD;
}
.logo-titleEvalgator{
    margin-bottom: 50px;
}
.logo-titleEvalgator .catch-copyEvalgator{
    color: #4EB04E;
    font-weight:700;
    text-align: center;

}
.logo-titleEvalgator .catch-copy-2Evalgator{
    color: #0B290D;
    text-align: center;
    line-height: 20px;
    margin-top: 10px;
}
.logo-titleCrosschannel .catch-copyCrosschannel{
    color: #604CA5;
    font-weight: 800;
    word-wrap: break-word;
    text-align: center;
    padding-top: 10px;
    font-family: 'Noto Sans JP', sans-serif;
    
}
.logo-titleCrosschannel .catch-copy-2Crosschannel{
    color: #0C093B;
    line-height: 20px;
    text-align: center;
    word-wrap: break-word;
    margin-top: 10px;
    font-weight: 800;
    padding-bottom: 20px;
    font-family: 'Noto Sans JP', sans-serif;
}
/* Title-Enlite */
.logoEnlite{
    /* padding-top: 30px; */
   width: 800px;
}
.logo-titleEnlite{
    margin-bottom: 50px;
}
.logo-titleEnlite .catch-copyEnlite{
    color: #008DDF;
    font-weight:700;
    text-align: center;

}
.logo-titleEnlite .catch-copy-2Enlite{
    color: #0C093B;
    text-align: center;
    line-height: 20px;
    margin-top: 10px;
}
/* Title-Rehash */
.logoRehash{
   padding-top: 30px; 
   width: 350px;
}
.logo-titleRehash{
    margin-bottom: 50px;
    background: #FFF4E6;

}
.logo-titleRehash .catch-copyRehash{
    background: linear-gradient(to bottom, #ED202A, #FF8E01);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight:700;
    text-align: center;

}
.logo-titleRehash .catch-copy-2Rehash{
    color: #0C093B;
    text-align: center;
    line-height: 20px;
    margin-top: 10px;
}
.backgroundRehash{
    background:#FFF4E6;
}
/* Title-Finbooks */
.backgroundFinbooks{
    background: #e7effd;
}
.logoFinbooks{
   padding-top: 40px; 
   padding-bottom: 20px;
   width: 330px;
}
.logo-titleFinbooks{
    margin-bottom: 50px;
    background: #e7effd;
}
.logo-titleFinbooks .catch-copyFinbooks{
    background: linear-gradient(to bottom, #0D5BE6, #1D91F4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 20px;
    font-weight:700;
    text-align: center;


}
.logo-titleFinbooks .catch-copy-2Finbooks{
    color: #323E48;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    text-align: center;
    font-weight: 600;

}

/* Title-SlashQ*/
.logoSlashQ{
    padding-top: 40px; 
    width: 300px;
 }
 .logo-titleSlashQ{
     margin-bottom: 50px;
     background-color: #FCFBF9;
    }
 .logo-titleSlashQ .catch-copySlashQ{
     color: #FF5958;
     font-size: 20px;
     font-weight:700;
     text-align: center;
 
 }
 .logo-titleSlashQ .catch-copy-2SlashQ{
     color: #323E48;
     font-size: 15px;
     text-align: center;
     line-height: 20px;
     text-align: center;
     font-weight: 600;
 }
 .backgroundSlashQ{
    background-color: #FCFBF9;
 }

 /* Title-Affiliate*/
 
.logoAffiliate{
    padding-top: 40px; 
    padding-bottom: 20px;
    width: 300px;
 }
 .logo-titleAffiliate{
     margin-bottom: 50px;
     background: #EDF7F4;
 }
 .logo-titleAffiliate .catch-copyAffiliate{
     color: #E63253;
     font-size: 20px;
     font-weight:700;
     text-align: center;
 
 }
 .logo-titleAffiliate .catch-copy-2Affiliate{
     color: #414141;
     font-size: 15px;
     text-align: center;
     line-height: 20px;
     margin-top: 10px;
     text-align: center;
     font-weight: 600;
     font-family: 'Noto Sans JP', sans-serif;
     
 }
 .backgroundAffiliate{
    background:#EDF7F4;}
 

 /* アバウトセクション */
.about{
    min-height: 286px;    
}
/* About-Evalgator */
.aboutEvalgator .About-titleEvalgator{
    color: #4EB04E;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    text-align: center;
    word-wrap: break-word
    
}
.aboutEvalgator .About-subtitleEvalgator{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.aboutEvalgator{
    background: #d4ecd4;
    padding-top: 30px;
    padding-bottom: 20px;
}
.aboutEvalgator .product-descriptionEvalgator{
    color: #0B290D;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
    
}

/* About-Crosschannel */
.aboutCrosschannel{
    background-color: #F4EFFE;

}
.aboutCrosschannel .About-titleCrosschannel{
    color: #604CA5;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align: center;
    padding-top: 30px;
}
.aboutCrosschannel .About-subtitleCrosschannel{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.aboutCrosschannel{
    padding-top: 20px; 
    padding-bottom: 20px;
}
.aboutCrosschannel .product-descriptionCrosschannel{
    color:#312D3B;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
    font-family: 'Noto Sans JP', sans-serif;
}

/* About-Enlite */
.aboutEnlite .About-titleEnlite{
    color: #008DDF;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align: center;
    padding-top: 30px;
}
.aboutEnlite .About-subtitleEnlite{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;    
}
.aboutEnlite{
    
    padding-bottom: 20px;
    background: #E2F4FF;
}
.aboutEnlite .product-descriptionEnlite{
    color:#312D3B;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
}

/* About-Rehash */
.aboutRehash{
    padding-bottom: 20px;
    background: #FFF4E6;
}
.aboutRehash .About-titleRehash{
    background: linear-gradient(to bottom, #ED202A, #FF8E01);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align: center;
    padding-top: 30px;
}
.aboutRehash .About-subtitleRehash{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;    
}

.aboutRehash .product-descriptionRehash{
    color:#000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
}

/* About-SlashQ */
.aboutSlashQ{
    padding-bottom: 20px;
    background: #FCFBF9;
}
.aboutSlashQ .About-titleSlashQ{
    color: #FF5958;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align: center;
    padding-top: 30px;
}
.aboutSlashQ .About-subtitleSlashQ{
    color: #81878A;
    text-align: center;  
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
      
}
.aboutSlashQ .product-descriptionSlashQ{
    color:#000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
}
/* About-Affiliate */
.aboutAffiliate{
    padding-bottom: 20px;
    background: #EDF7F4;    
}
.aboutAffiliate .decoration{
    margin-left: 2px;
}
.aboutAffiliate .About-titleAffiliate{
    color: #4AA28A;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align: center;
    padding-top: 30px;
}
.aboutAffiliate .About-subtitleAffiliate{
    color: #81878A;
    text-align: center;  
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
      
}
.aboutAffiliate .product-descriptionAffiliate{
    color:#000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
    font-family: 'Noto Sans JP', sans-serif;

}

/* About-Finbooks */
.aboutFinbooks{
    padding-bottom: 20px;
    background:#e7effd;
}
.aboutFinbooks .decoration{
    margin-left: 2px;
}
.aboutFinbooks .About-titleFinbooks{
    background: linear-gradient(to bottom, #0D5BE6, #1D91F4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
    padding-top: 20px;
}

.aboutFinbooks .About-subtitleFinbooks{
    color: #81878A;
    text-align: center;  
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
      
}
.aboutFinbooks .product-descriptionFinbooks{
    color: #000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    line-height: 25px;
    word-wrap: break-word;
    margin:20px 40px;
}

/*ソリューションセクション */

/* Solution-Evalgator */
.solutionEvalgator{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.solutionEvalgator .solution-titleEvalgator{
    color: #4EB04E;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionEvalgator .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionEvalgator .solution-listEvalgator {
    margin: 0;
    padding: 0 30px;
}
.solutionEvalgator .solution-listEvalgator li{
    color: #0B290D;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/GreenCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.solutionEvalgator .solution-listEvalgator li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* Solution-CrossChannel */
.solutionCrosschannel{
    background: #F3EEFD;
    padding-top: 30px;
    padding-bottom: 30px;
}
.solutionCrosschannel .solution-titleCrosschannel{
    color: #604CA5;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionCrosschannel .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionCrosschannel .solution-listCrosschannel {
    margin: 0;
    padding: 0 30px;
}
.solutionCrosschannel .solution-listCrosschannel li{
    color: #312D3B;
    font-size: 15px;
    font-weight: 400;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/PurpleCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.solutionCrosschannel .solution-listCrosschannel li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
/* Solution-Enlite */
.solutionEnlite{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.solutionEnlite .solution-titleEnlite{
    color: #008DDF;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionEnlite .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionEnlite .solution-listEnlite {
    margin: 0;
    padding: 0 30px;
}
.solutionEnlite .solution-listEnlite li{
    color: #0B290D;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/BlueCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.solutionEvalgator .solution-listEvalgator li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
/* Solution-Rehash */
.solutionRehash{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #FFF4E6;
}
.solutionRehash .solution-titleRehash{
    background: linear-gradient(to bottom, #ED202A, #FF8E01);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionRehash .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionRehash .solution-listRehash {
    margin: 0;
    padding: 0 30px;
}
.solutionRehash .solution-listRehash li{
    color: #000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/RedCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.solutionRehash .solution-listRehash li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* Solution-Finbooks */
.solutionFinbooks{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background:#e7effd;;    
}
.solutionFinbooks .solution-titleFinbooks{
    background: linear-gradient(to bottom, #0D5BE6, #1D91F4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionFinbooks .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionFinbooks .solution-listFinbooks {
    margin: 0;
    padding: 0 30px;
}
.solutionFinbooks .solution-listFinbooks li{
    color: #000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/FinCeck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.solutionFinbooks .solution-listFinbooks li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
/* Solution-SlashQ */
.solutionSlashQ{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #FCFBF9;
}
.solutionSlashQ .solution-titleSlashQ{
    color: #FF5958;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionSlashQ .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionSlashQ .solution-listSlashQ {
    margin: 0;
    padding: 0 30px;
}
.solutionSlashQ .solution-listSlashQ li{
    color: #000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/PinkCheck.svg)  left center no-repeat; 
    padding-left: 50px;
  
}
.solutionSlashQ .solution-listSlashQ li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* Solution-Affiliate(PartnerFront) */
.solutionAffiliate{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #EDF7F4;
}
.solutionAffiliate .solution-titleAffiliate{
    color: #4AA28A;
    font-size: 34px;
    font-family: Roboto;
    font-weight: 800;
    word-wrap: break-word;
    text-align:center;
}
.solutionAffiliate .solution-subtitle{
    color: #81878A;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.solutionAffiliate .solution-listAffiliate {
    margin: 0;
    padding: 0 30px;
}
.solutionAffiliate .solution-listAffiliate li{
    color: #000000;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 00;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/PartnerCheck.svg)  left center no-repeat; 
    padding-left: 50px;
    font-family: 'Noto Sans JP', sans-serif;
  
}
.solutionAffiliate .solution-listAffiliate li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}



/* button css section */
/* button-CrossChannel */
.buttonSectionCrosschannel{
    display: flex; 
    justify-content: center; 
    gap: 25px;
    margin-bottom: 30px;
}
.WebButtonCrosschannel{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background: #604CA5; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
    margin-bottom: 30px;
}

.BrochureButtonCrosschannel{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background: #604CA5; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
        margin-bottom: 30px;
}

/* button-Evalgator */
.buttonSectionEvalgator{
    display: flex;
    justify-content: center; 
    gap: 25px;
}
.WebButtonEvalgator{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background: #4EB04E; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.BrochureButtonEvalgator{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background: #4EB04E; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* button-PartnerFront */
.buttonSectionAffiliate{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-bottom: 20px;
}
.WebButtonAffiliate{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background: #61A08B; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.BrochureButtonAffiliate{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background: #61A08B; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* button-Finbooks */
.buttonSectionFinbooks{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-bottom: 30px;
}
.WebButtonFinbooks{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background-image: linear-gradient(to bottom, #0D5BE6, #1D91F4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.BrochureButtonFinbooks{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background-image: linear-gradient(to bottom, #0D5BE6, #1D91F4);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* button-SlashQ */
.buttonSectionSlashQ{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-bottom: 30px;
}
.WebButtonSlashQ{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background: #FF5958; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.BrochureButtonSlashQ{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background: #FF5958; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* button-Enlite */
.buttonSectionEnlite{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-bottom: 30px;
}
.WebButtonEnlite{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background: #008DDF; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.BrochureButtonEnlite{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background: #008DDF; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}
/* button-Rehash */
.buttonSectionRehash{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-bottom: 30px;
}
.WebButtonRehash{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background-image: linear-gradient(to bottom, #ED202A, #FF8E01);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.BrochureButtonRehash{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background-image: linear-gradient(to bottom, #ED202A, #FF8E01);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}
.description title{
    color: #008DDF;
}