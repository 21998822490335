.productPage-pc{
    padding-bottom: 51px;
}
.align-center{
    max-width: 1200px;
    margin: 0 auto;
}
.logo-titleEvalgator-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoEvalgator-pc{
    margin: 69.55px 86px 36px 0;
    height: 48px;
    width: 221px;
}
.product-catch-description-container-pc{
    margin: 70px 0 36px 0;
}
.catch-copyEvalgator-pc{
    color: #4EB04E;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
}
.catch-copy-2Evalgator-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.catch-copy-2Evalgator-pc p:not(:last-of-type){
    margin-bottom: 9px;
}

.assessment-section-wrapper-Evalgator,
.interview-section-wrapper-Evalgator{
    background-color: #D7FBD0;
    padding: 0 20px;
}
.assessmentEvalgator-pc,
.interviewEvalgator-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
} 
.assessment-logo-title-wrapper,
.interview-logo-title-wrapper{
    max-width: 407px;
}
.assessment-logo-container,
.interview-logo-container{
    width: 100%;
    display: flex;
}
.assessment-logo-pc,
.interview-logo-pc{
    min-width: 100%;
}
.assessment-title-pc,
.interview-title-pc{
    color: #4EB04E;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
    padding-bottom: 11px;
    word-wrap: break-word;
}
.decoration-image-container-pc{
    max-width: 530px;
    display: flex;
}

.solution-titleEvalgator-pc{
    margin: 40px 0 1px 0;
    color: #0B290D;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-subtitle-pc{
    color: #81878A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 14px;
}
.solution-listEvalgator-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionEvalgator-pc .solution-listEvalgator-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/GreenCheck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionEvalgator .solution-listEvalgator li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
.assessment-button-pc,
.interview-button-pc
{
    padding: 20px 0;
}
.assessment-button-pc button,
.interview-button-pc button{
    cursor: pointer;
}
.interview-button-pc button + button {
    margin-left: 50px;
}
.assessment-button-pc button:first-child,
.interview-button-pc button:first-child{
    margin-left: 4px;
}