.productPage-pc{
    padding-bottom: 51px;
}
.align-center{
    max-width: 1200px;
    margin: 0 auto;
}
.product-description__text-pc{
    max-width: 407px;
    width: 100%;
    height: 100px;
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.188%;
    word-wrap: break-word;
    min-width: 260px;
}
.decoration-image-container{
    max-width: 530px;
}
.decoration-pc{
    max-width: 100%;
    height: auto;
    width: auto\9;
}
/* Cross Channel */
.logo-titleCrosschannel-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoCrosschannel-pc{
    margin: 69.55px 86px 36px 0;
    height: 95px;
    width: 221px;
}
.catch-copyCrosschannel-pc{
    color: #604CA5;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
    margin-top: 20px;
}
.catch-copy-2Crosschannel-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.catch-copy-2Crosschannel-pc p:not(:last-of-type){
    margin-bottom: 9px;
}

.about-section-wrapper-Crosschannel{
    background-color: #F4EFFE;
    padding: 0 20px;
}
.About-titleCrosschannel-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: #604CA5;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleCrosschannel-pc{
    width: 126px;
    height: 16px;
    color: #0C093B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.aboutCrosschannel-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.solution-titleCrosschannel-pc{
    margin: 40px 0 1px 0;
    color: #604CA5;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-listCrosschannel-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionCrosschannel-pc .solution-listCrosschannel-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/PurpleCheck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionCrosschannel .solution-listCrosschannel li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
.WebButtonCrosschannel,
.BrochureButtonCrosschannel{
    background: #604CA5;
    margin: 0;
}

/* Enlite */
.logo-titleEnlite-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoEnlite-pc{
    margin: 69.55px 86px 36px 0;
    height: 95px;
    width: 221px;
}
.catch-copyEnlite-pc{
    color: #008DDF;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
}
.catch-copy-2Enlite-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
}
.catch-copy-2Enlite-pc p:not(:last-of-type){
    margin-bottom: 9px;
}

.about-section-wrapper-Enlite{
    background-color: #E2F4FF;
    padding: 0 20px;
}
.About-titleEnlite-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: #008DDF;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleEnlite-pc{
    width: 126px;
    height: 16px;
    color: #0C093B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.aboutEnlite-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.solution-titleEnlite-pc{
    margin: 40px 0 1px 0;
    color: #008DDF;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-listEnlite-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionEnlite-pc .solution-listEnlite-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/BlueCheck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionEnlite .solution-listEnlite li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* Rehash */
.logo-titleRehash-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoRehash-pc{
    margin: 69.55px 86px 36px 0;
    height: 95px;
    width: 221px;
}
.catch-copyRehash-pc{
    color: transparent;
	background: linear-gradient(to bottom, #ED202A, #FF8E01);
    background-clip: text;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
    margin-top: 40px;
}
.catch-copy-2Rehash-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}
.catch-copy-2Rehash-pc p:not(:last-of-type){
    margin-bottom: 9px;
}
.about-section-wrapper-Rehash{
    background-color: #FFF4E6;
    padding: 0 20px;
}
.About-titleRehash-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: transparent;
	background: linear-gradient(to bottom, #ED202A, #FF8E01);
    background-clip: text;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleRehash-pc{
    width: 126px;
    height: 16px;
    color: #0C093B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.aboutRehash-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.solution-titleRehash-pc{
    margin: 40px 0 1px 0;
    color: transparent;
	background: linear-gradient(to bottom, #ED202A, #FF8E01);
    background-clip: text;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-listRehash-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionRehash-pc .solution-listRehash-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/RedCheck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionRehash .solution-listRehash li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* Finbooks */
.logo-titleFinbooks-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoFinbooks-pc{
    margin: 69.55px 86px 36px 0;
    height: 95px;
    width: 221px;
}
.catch-copyFinbooks-pc{
    background: linear-gradient(to bottom, #0D5BE6, #1D91F4);
    -webkit-background-clip: text;
    background-clip: text;
	color: transparent;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
    margin-top: 20px;
}
.catch-copy-2Finbooks-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.catch-copy-2Finbooks-pc p:not(:last-of-type){
    margin-bottom: 9px;
}

.about-section-wrapper-Finbooks{
    background-color: #0C49B7;
    padding: 0 20px;
}
.About-titleFinbooks-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleFinbooks-pc{
    width: 126px;
    height: 16px;
    color:  #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.aboutFinbooks-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-descriptionFinbooks-pc .product-description__text-pc{
    color: #FFFFFF;
}
.solution-titleFinbooks-pc{
    margin: 40px 0 1px 0;
    background: linear-gradient(to bottom, #0D5BE6, #1D91F4);
    -webkit-background-clip: text;
    background-clip: text;
	color: transparent;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-listFinbooks-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionFinbooks-pc .solution-listFinbooks-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/FinCeck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionFinbooks .solution-listFinbooks li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* SlashQ */
.logo-titleSlashQ-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoSlashQ-pc{
    margin: 69.55px 86px 36px 0;
    height: 95px;
    width: 221px;
}
.catch-copySlashQ-pc{
    color: #FF5958;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
}
.catch-copy-2SlashQ-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.catch-copy-2SlashQ-pc p:not(:last-of-type){
    margin-bottom: 9px;
}
.about-section-wrapper-SlashQ{
    background-color: #FCFBF9;
    padding: 0 20px;
}
.About-titleSlashQ-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: #FF5958;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleSlashQ-pc{
    width: 126px;
    height: 16px;
    color: #0C093B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.aboutSlashQ-pc{
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.aboutSlashQ-pc .decoration-image-container img{
    max-width: 349px;
}
.solution-titleSlashQ-pc{
    margin: 40px 0 1px 0;
    color: #FF5958;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-listSlashQ-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionSlashQ-pc .solution-listSlashQ-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/PinkCheck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionSlashQ .solution-listSlashQ li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}

/* Affiliate */
.logo-titleAffiliate-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoAffiliate-pc{
    margin: 69.55px 86px 36px 0;
    height: 95px;
    width: 221px;
}
.catch-copyAffiliate-pc{
    color: #4AA28A;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
    margin-top: 20px;
}
.catch-copy-2Affiliate-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.catch-copy-2Affiliate-pc p:not(:last-of-type){
    margin-bottom: 9px;
}

.about-section-wrapper-Affiliate{
    background-color:#EDF7F4;
    padding: 0 20px;
}
.About-titleAffiliate-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: #4AA28A;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleAffiliate-pc{
    width: 126px;
    height: 16px;
    color: #0C093B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.aboutAffiliate-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.solution-titleAffiliate-pc{
    margin: 40px 0 1px 0;
    color: #4AA28A;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.solution-listAffiliate-pc{
    margin: 0;
    padding: 0 0px;
}
.solutionAffiliate-pc .solution-listAffiliate-pc li{
    color: #0B290D;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
    text-align-last: left;
    padding: 20px 0;
    list-style: none;
    background: url(/public/productPage/PartnerCheck.svg)  left center no-repeat; 
    padding-left: 50px; 
}
.solutionAffiliate .solution-listAffiliate li:not(:last-of-type){ 
    border-bottom: 0.50px solid #E0E0E0 ;
}
.product-button-pc button{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    border: none;
}
.product-button-pc button + button {
    margin-left: 50px;
}
.product-button-pc button:first-child{
    margin-left: 4px;
}
.header-pc-wrapper,
.banner-section-wrapper,
.solution-section-wrapper,
.product-button-pc-wrapper{
    padding: 0 20px;
}