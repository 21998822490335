.accordionMenuItems ul {
    padding: 0 4px;
    margin: 0;
}
.accordionMenuItems ul li {
    padding: 15px 0;
    border-bottom: 1px solid #E0E0E0;
    line-height: 1.5rem;
    list-style: none;
    background-image: url("/public/menu/arrowCircle.svg");
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    background-size: 24px;
}
.accordionMenuItems ul li .content { 
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color:#222222;
}

.accordionMenuItems ul li .description{
    font-size: 0.813rem;
    font-weight: 400;
    color:#222222;
    margin-left:0.625rem;
    line-height: 1.5rem;
}