.button{
    display: flex;
    width: 235px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #D33E38;
    color:#D33E38;
    background-color: #fff;
}
.button.button-bordered{
    color:#D33E38;
    border: 1px solid #D33E38;
} 
.button.button-solid {
    background-color: #D33E38;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
}
.button span{
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.button.button-plain{
    color: #96A1A8;
    font-family: Roboto;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.21875rem */
    border: none;
    border-radius: 0.375rem;
    background: #F3F3F3;
}
