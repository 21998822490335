.SectionSeeMyProduct{
    background:#EBF4F9;
    
}
.SeeMyLogo{
    padding:50px 20px 30px 20px;
}
.SeeMyDescription{
    color: #00456B;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 17.74px;
    word-wrap: break-word;
    text-align: center;
}
/* Transporter */
.SectionSeeMyTrans{
    text-align: center;
}
.TransLogo{
    padding: 0px 0px 6px 0px;
}

.TransTitile{
    color: #00456B;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word; 
    margin:0px 0px 11px 0px;
    text-align:center;
}
.TransAbout{

    color: #00456B;
    font-size: 30px;
    font-weight: 800;
    padding: 10px 0px 0px 0px;
    border-top-right-radius: 20px;
    text-align:center;
}
.TransAboutTitle{
    color: #81878A;
    font-size: 14px;
    font-weight: 600;
    word-wrap: break-word;
    background: (255, 255, 255, 0.90);
    text-align:center;
}
    
.TransDescription{
    color: #1D466A;
    font-size: 15px;
    font-weight: 300;
    line-height: 19.45px;
    word-wrap: break-word;
    padding:20px 40px 30px 40px;
   
    
    
}
.AboutTrans{
    background: rgba(255, 255, 255, 0.90);
    width: 325px;
    flex-direction: column;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    position: absolute;
    top: 35rem;
    left: 65px;
}

/* TaskManagement */
.SectionTask{
    text-align: center;
}
.SeeMyTask{
    padding: 116px 0px 0px 0px;
}

.TaskLogo{
    padding: 0px 0px 6px 0px;
    
}
.TaskTitile{
    color: #00456B;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
    
}
.TaskAbout{
    color: #00456B;
    font-size: 30px;
    font-weight: 800;
    word-wrap: break-word;
    padding: 10px 0px 0px 0px;
    text-align: center;
}
.TaskAboutTitle{
    color: #81878A;
    font-size: 14px;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.TaskDescription{
    color: #1D466A;
    font-size: 15px;
    font-weight: 300;
    line-height: 19.45px;
    word-wrap: break-word;
    padding:20px 40px 30px 40px;
    
}
.AboutTask{
    background: rgba(255, 255, 255, 0.90);
    width: 325px;
    flex-direction: column;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    position: absolute;
    top: 82rem;
    left:65px; 
}

/* Gauzes */
.SectionGauzes{
    text-align: center;
}
.SeeMyGauzes{
    padding:116px 0px 0px 0px
}

.GauzesTitile{
    color: #00456B;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
    margin: 0px 0px 11px 0px;
}
.GauzesAbout{

    color: #00456B;
    font-size: 30px;
    font-weight: 800;
    word-wrap: break-word;
    padding: 10px 0px 0px 0px;
    text-align: center;
}
.GauzesAboutTitle{
    color: #81878A;
    font-size: 14px;
    font-weight: 600;
    word-wrap: break-word;
    text-align: center;
}
.GauzesDescription{
    color: #1D466A;
    font-size: 15px;
    font-weight: 300;
    line-height: 19.45px;
    word-wrap: break-word;
    padding:20px 40px 30px 40px;
    
}

.AboutGauzes{
    background: rgba(255, 255, 255, 0.90);
    width: 325px;
    flex-direction: column;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    position: absolute;
    top: 126rem;
    left:65px;  
}


/* SSD */
.SectionSSD{
    text-align: center;
}
.SeeMySSD{
    padding:116px 0px 0px 0px
}

.SsdTitile{
    color: #00456B;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
    margin: 0px 0px 11px 0px;
}
.SsdAbout{
    text-align: center;
    color: #00456B;
    font-size: 30px;
    font-weight: 800;
    word-wrap: break-word;
    padding: 10px 0px 0px 0px;


}
.SsdAboutTitle{
    text-align: center;
    color: #81878A;
    font-size: 14px;
    font-weight: 600;
    word-wrap: break-word;
    
}
.SsdDescription{
    color: #1D466A;
    font-size: 15px;
    font-weight: 300;
    line-height: 19.45px;
    word-wrap: break-word;
    padding:20px 40px 30px 40px;
}
.AboutSsd{
    background: rgba(255, 255, 255, 0.90);
    width: 325px;
    flex-direction: column;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    position: absolute;
    top: 170rem;
    left:65px;  
}
.SsdDec{
    padding-bottom: 230px;

}

/* Bottun */
/* Transporter Bottun */

.TransButton{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-top: 251px;
}
.TransWebButton{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background-color: #00456B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.TransBrochureButton{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background-color: #00456B;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* Task Manaement Bottun */
.TaskButton{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-top: 247px;
}
.TaskWebButton{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background-color: #00456B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.TaskBrochureButton{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background-color: #00456B;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}
/* Task Manaement Bottun */
.GauzesButton{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-top: 226px;

}
.GauzesWebButton{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background-color: #00456B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.GauzesBrochureButton{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background-color: #00456B;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

/* SSD Bottun */
.SSDButton{
    display: flex;
    justify-content: center; 
    gap: 25px;
    padding-top: 20px;
    padding-bottom: 30px;
}
.SSDWebButton{
    color: white;
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    padding: 10px 15px; 
    background-color: #00456B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 5px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    border: none; 
}

.SSDBrochureButton{
        color: white;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 600;
        padding: 10px 15px; 
        background-color: #00456B;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        border-radius: 5px; 
        justify-content: center; 
        align-items: center; 
        gap: 10px; 
        display: inline-flex;
        border: none; 
}

.SeeMyDescription2{
    padding:0px 20px;
    font-size: 14px;
    text-align: center;
    padding-bottom: 50px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
}