.accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 35px 24px 10px;
    text-align: center;
}
.accordion {
    border-bottom: 1px solid #CAD4DB;
}
.accordion-logo-container{
    margin-bottom: 15px;
}
.accordion-logo-container .accordion-logo--img{
    max-height: 76px;
    display: block;
}

.accordion-header--title{
    color: #000;
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: 500;
    margin: -15px;
    margin-bottom:30px;
    text-align: center;
}
.accordion.accordion-expanded .accordion-header--title {
    margin-bottom:50px;
}

.accordion-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.accordion-content .accordion-content--img{
    max-width: 284px;
    width: 100%;
}

.accordion-content--description{
    text-align: left;
    margin-bottom: 15px;
    margin-left: 10px;
}

.accordion-content--description ul {
    color: #000;
    font-size: 	0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    list-style-position: inside;
    padding-left: 0;
    list-style-type: "・";
    padding-bottom: 20px;
}

.accordion-content .accordion-content--button {
    margin-bottom: 55px;
}
.accordion-header--arrow{
    width: 32.5px;
    height: 26.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accordion-header--arrow.accordion-header--arrow--expanded {
    transform: rotate(180deg);
}
