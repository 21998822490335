@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
body {
  margin: 0 auto;
  line-height: normal;
  font-family: Roboto;
  box-sizing: border-box;
}
body:lang(en) {
  font-family: 'Noto Sans JP', sans-serif;
}
body:lang(jp) {
  font-family: Arial, Helvetica, sans-serif;
}
img{ max-width:100%; }

@media screen and (max-width: 992px)  {
  body{
    max-width: 390px;
  }
}