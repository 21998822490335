.footer{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* height: 285px; */
    margin: 5px 0;
    font-size: 12px;
}
.contact-us{
    height: 51px;
    width: 286px;
    display: flex;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    margin: 50px 52px 184px;
    border-radius: 5px;
    background: #F8E4E5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
}

@media screen and (min-width: 700px)  {
    .footer{
        margin: 0;
        align-items: center;
        justify-content: center;
        padding: 59px 0;
    }
  }