.home{
    display: flex;
    flex-direction: column;
}
.product-main-title {
    padding: 30px 25px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    font-family: 'Noto Sans JP', sans-serif;
}
.product-main-title .product-into--title{
    position: relative;
    color: #222222;
    font-size: 36px;
    font-weight: 900;
    line-height: 72px;
    text-wrap: nowrap;
    line-height: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: 2px;
    z-index: 30;
    top: 66px;
    right: 5px;
}

.product-main-title .product-into--title2{
    position: relative;
    color: #E8363B;
    font-size: 12px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
    letter-spacing: 2px;
    z-index: 20;
    bottom: 24px;
    right: 3px;
}
.product-main-title .product-into--title-background{
    font-family: 'Noto Sans JP', sans-serif;
    position: absolute;
    color: #FFF5F6;
    font-size: 71px;
    font-family: Roboto;
    font-weight: 900;
    line-height: 150px;
    text-wrap: nowrap;
    z-index: 0;
    top: 49rem;
    left: 17px;
}
.accordion ~ .footer { 
    margin-top: 25px;
    border-top: 1px solid #CAD4DB; 
}

.accordion-content--description{
    text-align: center;
}

.OfferingProduct{
    padding: 15px 25px 10px 25px;
    font-size: 12px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    border-bottom: 1px solid #CAD4DB; 
    padding-bottom: 30px; 
    line-height: 18px;
}