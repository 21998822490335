.productPage-pc{
    padding-bottom: 51px;
}
.align-center{
    max-width: 1200px;
    margin: 0 auto;
}
.logo-titleSeeMyMachines-pc{
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.logoSeeMyMachines-pc{
    margin: 69.55px 86px 36px 0;
    height: 48px;
    width: 221px;
}
.product-catch-description-container-pc{
    margin: 70px 0 36px 0;
}
.catch-copySeeMyMachines-pc{
    color: #00456B;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
}
.catch-copy-2SeeMyMachines-pc{
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.catch-copy-2SeeMyMachines-pc p:not(:last-of-type){
    margin-bottom: 9px;
}

.section-logo-container-pc{
    text-align: center;
}
.section-product-logo{
    padding: 0px 0px 6px 0px;
    margin-top: 100px;
}
.section-product-title{
    color: #00456B;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word; 
    margin:0px 0px 11px 0px;
    text-align:center;
}
/* About section */
.about-product-text-container{
    margin-right: auto;
    max-width: 407px;
}
.about-section-wrapper-SeeMyMachines{
    background-color:  #EBF4F9;
    padding: 0 20px;
}
.About-titleSeeMyMachines-pc{
    margin-bottom: 1px;
    width: 93px;
    height: 40px;
    color: #00456B;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.About-subtitleSeeMyMachines-pc{
    width: 126px;
    height: 16px;
    color:#81878A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 28px;
}
.product-description__text-pc{
    max-width: 407px;
    width: 100%;
    height: 100px;
    color: #3C3C3C;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.188%; /* 19.71px */
    word-wrap: break-word;
    min-width: 260px;
}
.decoration-image-container{
    max-width: 530px;
}
.decoration-pc{
    max-width: 100%;
    height: auto;
    width: auto\9;
}
.aboutSeeMyMachines-pc{
    padding: 32px 0;
    min-height: 349px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-button-pc{
    padding: 20px 0;
}
.product-button-pc button + button {
    margin-left: 50px;
}