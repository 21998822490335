img{max-width: 100%;}
.banner-pc {
    font-family: 'Roboto', sans-serif;
    color: #222222;
    background-image: url("/public/home/main-content-image-bg.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    padding: 80px 20px 0;
    margin-bottom: 80px;
}
.banner-pc h1{
    color: #E8363B;
    font-size: 80px;
    margin: 0;
    font-weight: 900;
}
.banner-pc h4{ 
    margin-top: -11px;
    font-weight: 500;
    font-size: 16px;
}
.banner-pc h2{
    max-width: 510px;
    font-size: 24px;
    line-height: 48px;
    font-weight: 600;
    margin: 0;
}
.container-pc {
    max-width: 1200px;
    margin: 0 auto;
}
.banner-pc .bannerColContainer-pc {
    align-items: stretch;
    display: flex;
    margin-bottom: 27px;
}
.banner-pc .bannerColContainer-pc .bannerCols-pc {
    flex: 0 0 50%;
    display: flex;
    max-width: 50%;
    flex-direction: column;
    justify-content: space-between;
}
.banner-pc .bannerDesc-pc {
    font-size: 16px;
    line-height: 30.72px;
    margin: 0;
    font-weight: 400;
}
.banner-pc .bannerColContainer-pc .bannerCols-pc .bannerImg-pc img {
    max-height: 403px;
    margin-left: auto;
    max-width: 100%;
}
